import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

import Layout from '../components/layout'
import { Typography, Box, Container, Paper, Grid } from '@material-ui/core'
import TablicaTecajevi from '../components/tablicaTecajevi'

const replaceSpaces = (text) =>
  text
    .replace(' kn', '&nbsp;kn')
    .replace('= ', '=&nbsp;')
    .replace(' PDV', '&nbsp;PDV')

const LargeItem = ({ title, subtitle, priceCalc, totalPrice }) => (
  <>
    {title && (
      <Typography
        variant="h5"
        dangerouslySetInnerHTML={{ __html: replaceSpaces(title) }}
      />
    )}
    {subtitle && (
      <Typography
        variant="subtitle2"
        dangerouslySetInnerHTML={{ __html: replaceSpaces(subtitle) }}
      />
    )}
    {priceCalc && (
      <Typography variant="h6">
        <small dangerouslySetInnerHTML={{ __html: replaceSpaces(priceCalc) }} />
      </Typography>
    )}
    {totalPrice && (
      <Typography variant="h5" paragraph color="primary" align="center">
        <b dangerouslySetInnerHTML={{ __html: replaceSpaces(totalPrice) }} />
      </Typography>
    )}
  </>
)

const SmallItem = ({ title, subtitle, price }) => (
  <Grid item component={Box} width="290px" my={3}>
    <small>
      {title && (
        <Typography
          variant="subtitle1"
          dangerouslySetInnerHTML={{ __html: replaceSpaces(title) }}
        />
      )}
      {/* {subtitle && (
        <Typography
          variant="subtitle2"
          dangerouslySetInnerHTML={{ __html: replaceSpaces("1h: " + subtitle) }}
        />
      )} */}
      {price && (
        <Typography variant="h6">
          <small dangerouslySetInnerHTML={{ __html: replaceSpaces(price) }} />
        </Typography>
      )}
    </small>
  </Grid>
)

const CjenikPage = ({ data }) => {
  return (
    <Layout fixed title="Cjenik usluga">
      <>
        <Typography paragraph variant="h3" color="primary" align="center">
          Cjenik usluga
        </Typography>

        <TablicaTecajevi />

        </>
    </Layout>
  )
}

export default CjenikPage
