import React from 'react'
import {
  Typography,
  Box,
  Container,
  ThemeProvider,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Collapse,
  Paper,
  Grid,
  IconButton,
} from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import ConditionalWrapper from './conditionalWrapper'

const createExpandData = (table) => ({ table })

const createRowData = (key, name, info, students, price, table) => ({
  key,
  name,
  info,
  students,
  price,
  table,
})

const replaceSpaces = (text) =>
  text
    .replace(' kn', '&nbsp;kn')
    .replace('= ', '=&nbsp;')
    .replace(' PDV', '&nbsp;PDV')

const lessions = [
  createRowData(
    'opci',
    'Tečaj općeg engleskog',
    `60 školskih sati | 1h = 45min<br/>
      (25,00kn + 6,25 PDV = 31,25kn)`,
    'min 7',
    '1.875,00 kn',
    [
      ['', '', '', '29,16kn + 7,29 PDV = 36,45kn', '6', '2.187,50 kn'],
      ['', '', '', '35,00kn + 8,75 PDV = 43,75kn', '5', '2.625,00 kn'],
      ['', '', '', '43,75 kn + 10,93 PDV = 54,68 kn', '4', '3.280,80 kn'],
      ['', '', '', '43,75 kn + 10,93 PDV = 54,68 kn', '3', '4.375,00 kn'],
    ]
  ),
  createRowData(
    'poslovni',
    'Tečaj poslovnog engleskog',
    `60 školskih sati | 1h = 45min<br/>
    (30,00kn + 7,5 PDV = 37,50kn)`,
    'min 7',
    '2.250,00 kn',
    [
      ['', '', '', '35,00kn + 8,75 PDV = 43,75kn', '6', '2.625,00 kn'],
      ['', '', '', '42,00kn + 10,50 PDV = 52,50kn', '5', '3.150,00 kn'],
      ['', '', '', '52,50kn + 13,12 PDV = 65,62kn', '4', '3.281,25 kn'],
      ['', '', '', '70,00kn + 17,50 PDV = 87,50kn', '3', '5.250,00 kn'],
    ]
  ),
  createRowData(
    'individualni 1',
    'Individualni sati',
    `1h = 60min<br/>
    (120,00kn + 30 PDV)`,
    '1',
    '150kn/h',
    [
      ['', '', '', 'tečaj engleskog/njemačkog/talijanskog jezika', '', 'Svaki 10. sat besplatno'],
      ['', '', '', '', '', 'Posebna ponuda- 30 sati za 3300 kn s PDV']
    ]
  ),
  createRowData(
    'individualni 2',
    'Individualni sati',
    `1h = 60min<br/>
    (180,00kn + 45 PDV)`,
    '2',
    '225kn/h',
    [
      ['', '', '', 'tečaj engleskog/njemačkog/talijanskog jezika', '', 'Svaki 10. sat besplatno'],
      ['', '', '', '', '', 'Posebna ponuda- 30 sati za 3300 kn s PDV']
    ]
  ),
]
const translations = [
  createRowData(
    '',
    'Prijevodi sa stranog na hrvatski jezik',
    `= (1 kartica = 1450 znakova) 90,00 + 22,50 PDV =`,
    '',
    '112,50 kn',
    [
    ]
  ),
  
  createRowData(
    '',
    'Prijevodi sa hrvatskog na strani jezik',
    `= (1 kartica = 1450 znakova) 100,00 + 25,00 kn PDV =`,
    '',
    '125,00 kn',
    [
    ]
  ),
  createRowData(
    '',
    'Čitanje dopisa i popunjavanje dokumenata',
    `= (1 stranica) = 70,00 kn + 12,50 kn PDV =`,
    '',
    '82,50 kn',
    [
    ]
  ),
]
function Row(props) {
  const { row } = props
  const [open, setOpen] = React.useState(false)
  //   const classes = uselessionstyles()

  return (
    <React.Fragment>
      {/* <TableRow className={classes.root}> */}
      <TableRow 
            onClick={() => setOpen(!open)}>
        {row?.table?.length > 0 &&<TableCell>
           <IconButton
            aria-label="proširi red"
            size="small"
            //onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>}
        <TableCell
          component="th"
          scope="row"
          dangerouslySetInnerHTML={{ __html: row.name }}
        ></TableCell>
        <TableCell
          align="center"
          style={{ padding: 0, lineHeight: '1em', maxWidth: '200px' }}
        >
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={{ __html: replaceSpaces(row.info) }}
          ></Typography>
        </TableCell>
        <TableCell
          align="right"
          dangerouslySetInnerHTML={{ __html: row.students }}
        ></TableCell>
        <TableCell
          align="right"
          dangerouslySetInnerHTML={{ __html: replaceSpaces(row.price) }}
        ></TableCell>
      </TableRow>
      {row?.table?.length > 0 && <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer style={{ width: '100%' }}>
              <Table aria-label="cijenik tečajeva">
                <TableBody>
                  {row.table.map((cells, idx) => {
                    const style = idx === row.table.length -1? {borderBottom:'none'} : {}
                    return (
                    <TableRow>
                      {cells &&
                        cells.map((cell, i) => {
                          switch (i) {
                            case 0:
                              return (
                                <TableCell
                                  align="right"
                                  dangerouslySetInnerHTML={{ __html: cell }}
                                  style={{ ...style, width: '25%' }}
                                ></TableCell>
                              )
                            case 3:
                              return (
                                <TableCell
                                  align="center"
                                  style={{
                                    ...style,
                                    padding: 0,
                                    lineHeight: '1em',
                                    maxWidth: '200px',
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    dangerouslySetInnerHTML={{
                                      __html: replaceSpaces(cell),
                                    }}
                                  ></Typography>
                                </TableCell>
                              )
                            default:
                              return (
                                <TableCell
                                  align="right"
                                  dangerouslySetInnerHTML={{ __html: cell }}
                                  style={{ ...style }}
                                ></TableCell>
                              )
                          }
                        })}
                    </TableRow>
                  )})}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>}
    </React.Fragment>
  )
}

export default class TablicaTecajevi extends React.Component {
  render() {
    return (
      <>
        <TableContainer>
          <Table aria-label="cijenik tečajeva">
            <TableHead>
              <TableRow>
                <TableCell id="tecajevi" colSPan={6}>Tečajevi</TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell align="left">Usluga</TableCell>
                <TableCell />
                <TableCell align="right">Broj polaznika</TableCell>
                <TableCell
                  align="center"
                  style={{ padding: 0, lineHeight: '1em' }}
                >
                  Cijena <br />
                  <small>po polazniku +PDV</small>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lessions.map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody>
          </Table>
          <br/>
          <br/>
          <br/>
          <Table aria-label="cijenik prijevoda">
            <TableHead>
              <TableRow>
                <TableCell id="prijevodi" colSPan={6}>Prijevodi</TableCell>
              </TableRow>
              <TableRow>
                
                <TableCell align="left">Usluga</TableCell>
                <TableCell />
                <TableCell align="right"></TableCell>
                <TableCell
                  align="center"
                  style={{ padding: 0, lineHeight: '1em' }}
                >
                  Cijena
                  <small>+PDV</small>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {translations.map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }
}
